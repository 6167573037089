.recorder {
  &.hasError {
    @apply border-red-500;
    .recorder {
      &-attach {
        @apply bg-red-50 border-red-500;
      }
    }
  }
  &-running {
    animation: scaleAnim 1s linear infinite;
  }
}
.withIcon {
  position: absolute;
  left: -12px;
  top: -3px;
}

@keyframes scaleAnim {
  0%,
  100% {
    transform: scale(0.8);
  }

  65% {
    transform: scale(1.4);
  }
}
