.tcon-hero-section {
    min-height: 100vh;
    background-color: var(--primary-color);
    @media (max-width: 575px) {
        min-height: 80vh;
    }
    @media (max-width: 960px) {
        min-height: 80vh;
    }
}

.tcon-about-img {
    position: relative;
    img {
        position: relative;
        z-index: 11;
    }
}