.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    @apply w-4/5;
    max-width: 700px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }