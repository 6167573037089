/* Application settings called from app.tsx. */

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;400;500;700&display=swap');
@import 'react-toastify/dist/ReactToastify.css';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import './custom-base-styles.scss';
@import './custom-components.scss';
@import './custom-utilities.scss';
@import './components/HeroSection.scss';
@import 'react-datepicker/dist/react-datepicker.css';