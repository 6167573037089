.bg-primary_color {
  background-color: var(--primary-color) !important;
  &:hover {
    background-color: var(--secondary-color) !important;
  }
}

.tcon-why-us-wrapper {
  background-color: var(--secondary-color);
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  padding: 50px 0;
}

.tcon-impact-wrapper {
  background-color: var(--primary-color);
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  padding: 50px 0;
}

.tcon-download-wrapper {
  background: #f4f2ff;
  padding: 40px 0 0 0;
}

.tcon-footer-wrapper {
  background-color: var(--primary-color);
}

.tcon-header-pages {
  background-color: var(--primary-color);
}

.hksa-sidemenu {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  right: -30px;
  padding: 30px;
  background: rgb(255 255 255 / 98%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transition-property: right, opacity, visibility;
  transition-property: right, opacity, visibility;
  &.active {
    right: 0px;
    opacity: 1;
    visibility: visible;
    z-index: 111;
  }
}

a {
  &.active {
    border-bottom: 1px Solid #fff;
    border-radius: 0;
  }
}

.tcon-msg {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 50%;
}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}

#audioPlayer {
  @media (min-width: 320px) {
    width: 240px;
  }
}

.tcon-audio-wrapper {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--secondary-color);
  padding: 30px 20px;
  text-align: start;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  .icon_cnt {
    display: block;
    width: 165px;
    img {
      margin: auto;
      max-width: 40px;
    }
  }
  h3,
  p {
    color: #FFF;
  }
  .tcon-audio-container {
    margin-inline-end: 15px;
  }
}

.box_contact_type {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  height: 140px;
  background-color: var(--secondary-color);
  padding: 30px 20px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &:before {
    content: '\f058';
    position: absolute;
    top: 10px;
    left: 10px;
    font-family: 'Font Awesome 5 Pro';
    font-size: 28px;
    color: #ffffff;
    font-weight: 300;
    opacity: 0;
  }
  .file_sty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    margin: 0;
  }
  .ver_txt {
    margin: auto;
    width: 100%;
    .icon_cnt {
      display: block;
      width: 100%;
      img {
        margin: auto;
        max-width: 40px;
      }
    }
    h3,
    p {
      display: block;
      width: 100%;
      margin: 10px 0;
      color: #FFFFFF;
    }
  }
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #186675;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

button {
  .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #186675;
    margin-inline-start: 10px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tcon-languages {
  justify-content: center;
  margin-block-start: 30px;
}

.fav-item {
  flex: 0 0 auto;
  width: 100%;
  max-width: 180px;
  height: 90px;
  margin: 0 15px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: 1px solid #ddd;
  background: #ffffff;
  border-radius: 5px;
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.fav-item input[type='radio'] {
  position: absolute;
  top: 15px;
  left: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: url('../images/check1-none.svg') center no-repeat;
}
.fav-item input[type='radio']:checked {
  background: url('../images/check1.svg') center no-repeat;
}

.rw_sale_serv-top {
  position: relative;
  padding: 30px 20px;
}
.rw_sale_serv {
  position: relative;
  padding: 30px 20px;
  border-top: 1px solid #e3e3e3;
}

.action_btn_ft button {
  background-color: var(--primary-color);
}

.rw_offer_item {
  position: relative;
  padding: 20px;
  border-bottom: 1px solid #e3e3e3;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  .soc_icon {
    max-width: max-content;
    width: auto;
    span {
      width: 90px;
      height: 90px;
      display: block;
      border: 1px solid var(--primary-color);
      border-radius: 12px;
      margin-inline-end: 20px;
      display: flex;
      padding: 20px;
      -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      img {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        filter: brightness(0.5);
      }
    }
  }
  .rw_offer_rt {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    .offer_txt_rw {
      .order_num {
        display: inline-block;
        background-color: var(--primary-color);
        color: #ffffff;
        font-size: 15px;
        border-radius: 25px;
        padding: 3px 15px;
        text-align: center;
        min-width: 60px;
        margin-bottom: 5px;
      }
      p {
        color: #717171;
        font-size: 16px;
      }
      .offer_meta {
        margin-top: 10px;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        li {
          float: right;
          margin-inline-end: 15px;
          color: rgba(113, 113, 113, 0.51);
          font-size: 14px;
          i {
            display: inline-block;
            margin-inline-end: 5px;
            color: #919191;
            font-size: 16px;
          }
        }
      }
    }
  }
  .rw_offer_lt_cn {
    text-align: center;
    width: 170px;
    .state_order {
      color: #f5c57b;
      background-color: #fef2e5;
      border-radius: 25px;
      font-size: 15px;
      padding: 8px 15px;
      display: inline-block;
      &.order-blue {
        color: #52a0ff;
        background-color: rgba(82, 160, 255, 0.16);
      }
      &.order-gray {
        color: #666;
        background-color: #f7f7f7;
      }
    }
    .sale_pay {
      color: #646464;
      font-size: 14px;
      span {
        display: inline-block;
        margin-inline-end: 5px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.state_order {
  color: #f5c57b;
  background-color: #fef2e5;
  border-radius: 25px;
  font-size: 15px;
  padding: 8px 15px;
  display: inline-block;
  &.order-blue {
    color: #52a0ff;
    background-color: rgba(82, 160, 255, 0.16);
  }
  &.order-gray {
    color: #666;
    background-color: #f7f7f7;
  }
  &.order-red {
    color: #CC3B3B !important;
    background-color: rgba(204,59,59,0.16) !important;
  }
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 30px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.page-link {
  position: relative;
  display: block;
  color: var(--primary-color);
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
}

.service_port_box {
  position: relative;
  background-color: #f8f8f8;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
  .service_port_cn {
    padding: 15px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    .owner_how {
      display: flex !important;
      .pic_owner {
        width: 80px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        }
      }
      .txt_owner {
        display: grid;
        margin-inline-start: 15px;
        h3 {
          color: #3a3a3a;
          font-size: 18px;
        }
      }
    }
  }
}

.box_wizerd_step {
  position: relative;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 20px;
  background-color: var(--primary-color);
  z-index: -1;
  background-color: var(--primary-color);
  .step_cn {
    position: relative;
    display: table;
    margin: auto;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 18px;
      left: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 33.33%);
      height: 4px;
      background-color: rgba(255, 255, 255, 0.6);
    }
    .step_item {
      width: 33.33%;
      float: right;
      text-align: center;
      position: relative;
      z-index: 1;
      .bx_num {
        display: flex;
        margin: auto;
        width: 40px;
        height: 40px;
        background-color: #ffffff;
        text-align: center;
        border-radius: 8px;
        border: 3px solid transparent;
        position: relative;
        z-index: 2;
        .bx_num_txt {
          margin: auto;
          color: var(--secondary-color);
          font-size: 12px;
          // display: none;
        }
      }
      p {
        color: #ffffff;
        font-size: 12px;
        margin-top: 10px;
        opacity: 0.6;
      }
      &.active {
        .bx_num {
          background-color: #ffffff;
          border-color: var(--secondary-color);
          .bx_num_txt {
            display: block;
          }
        }
        &::before {
          opacity: 1;
        }
      }
      &:nth-child(1) {
        &::before {
          width: 50%;
          left: 0;
          right: auto;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 18px;
        left: 46px;
        width: 100%;
        height: 4px;
        background-color: var(--secondary-color);
        opacity: 0;
      }
    }
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.rw_proCrd {
  z-index: 1;
  position: relative;
  margin-bottom: 10px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  .label_proCrd {
    h3 {
      width: 120px;
      color: #131e4b;
      font-size: 16px;
      font-weight: 700;
      &.w-full.text-red-500 {
        width: 100%;
        color: #ef4444;
      }
    }
  }
  audio {
    width: 100%;
    max-width: 100%;
  }
  p {
    color: #131e4b;
    font-size: 16px;
    &.w-full.text-red-500 {
      width: 100%;
      color: #ef4444;
    }
  }
  a {
    max-width: max-content;
    word-break: break-word;
  }
  &.flex-column {
    flex-direction: column !important;
  }
}

.OTPInput > div {
  direction: ltr;
}

.soc_icon {
  max-width: 20%;
  width: 10%;
  span {
    img {
      filter: brightness(0.5);
      max-width: 70%;
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.form-check {
  display: flex;
  align-items: center;
  min-height: 1.5rem;
  input.flexCheckDefault {
    width: 20px;
    height: 20px;
  }
  label {
    height: 45px;
    border-radius: 12px;
    margin-inline-start: 15px;
    background-color: #F8F8F8;
    text-align: center;
    line-height: 45px;
    text-align: center;
    color: rgba(64, 64, 64, 0.67);
    font-size: 15px;
    display: block;
    font-weight: normal;
    width: 100%;
    -webkit-box-shadow: 0px 3px 8px 0px transparent;
    -moz-box-shadow: 0px 3px 8px 0px transparent;
    box-shadow: 0px 3px 8px 0px transparent;
  }
}

.chat-container {
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  overflow: hidden;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E5E5E5;
  padding: 15px 10px;
}

.chat-header .btn-outline-primary {
  border-color: #E5E5E5;
}

.chat-head-user {
  display: flex;
  align-items: center;
}

.chat-head-user .img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 5px;
  overflow: hidden;
}

.chat-head-user .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.chat-head-user h3 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.chat-body {
  padding: 15px;
  height: 550px;
  overflow: hidden;
  overflow-y: scroll;
  margin-left: 15px;
  .send_time {
    background-color: #787777;
    padding: 10px 22px 10px 22px;
    border-radius: 11px;
    color: #ffffff;
  }
}

.chat-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.chat-item .img > div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.chat-item .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.chat-item > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 400px;
  min-width: 310px;
}

.chat-item-text {
  background: var(--primary-color);
  border-radius: 16px;
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
  position: relative;
  color: #FFF;
}

.chat-time {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.chat-item-text:before,
.chat-item-text:after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
  z-index: 1;
}

.chat-item-text:before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid var(--primary-color);
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.chat-item-text:after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.chat-to {
  flex-direction: row-reverse;
}

.chat-to > div {
  flex-direction: row-reverse;
}

.chat-to .chat-item-text {
  background-color: #F2F4F7;
  color: #212121;
}

.chat-to .chat-item-text:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-bottom-left-radius: 0;
  border-left: 1rem solid #F2F4F7;
  border-right: 0;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
  right: auto;
}

.chat-to .chat-item-text:after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
  left: -3px;
  transform: translate(-7px, -2px);
  width: 10px;
  right: auto;
}

.chat-to .img {
  margin-left: 0;
  margin-right: 10px;
}

.chat-to .chat-time {
  text-align: right;
}

.chat-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #f9f9f9;
}

.chat-footer textarea {
  background: #FCFCFD;
  width: 100%;
  border-radius: 16px;
  padding: 10px;
}

.chat-footer textarea {
  background: #F2F4F7;
  width: 100%;
  border-radius: 16px;
  padding: 10px;
}

.chat-footer .btn {
  white-space: nowrap;
  margin-right: 15px;
  border-radius: 16px;
}

.textare-with-btns {
  position: relative;
}

.recording {
  width: 150% !important;
}
.chat-btns {
  position: absolute;
  left: 40px;
  top: 50px;
  &.loading {
    label {
      opacity: 0;
    }
  }
  .loader {
    position: absolute;
    z-index: 11;
    top: -28%;
    left: 30%;
    width: 40px;
    height: 40px;
    border: 5px solid #ffffff;
    border-top: 5px solid var(--primary-color);
    background: var(--primary-color);
  }
  .audio-recorder {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    z-index: 1;
    &.recording {
      position: absolute;
      left: 20vw;
      background: #FFF;
      padding: 0px 15px;
      border-radius: 5px;
      width: 20vw !important;
    }
    .audio-recorder-mic {
      height: 20px;
      padding: 0;
    }
  }
}

.chat-btns label {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: auto;
  height: 24px;
  cursor: pointer;
  z-index: 1;
}

.chat-btns i {
  position: relative;
  z-index: 1;
  width: 24px;
  height: 24px;
  background: #f7f7f7;
  color: #000;
  font-size: 20px;
  border-radius: 5px;
  padding: 3px;
}

.chat-btns label input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}

.bk_balance {
  padding: 0 0 50px;
}

.bk_balance_group .row {
  margin: 0 -7.5px;
}

.bk_balance_group .row > div {
  padding: 0 7.5px;
}

.bk_balance_group {
  margin-bottom: 30px;
}

.box_balance_item {
  position: relative;
  margin-bottom: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  height: 120px;
}

.height_full {
  height: 255px !important;
}

.flex_wrp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.balance_item_content {
  width: 100%;
}

.balance_item_content h3 {
  color: #FFFFFF;
  font-size: 28px;
  white-space: pre-line;
}

.balance_item_content p {
  color: #FFFFFF;
  font-size: 24px;
  margin-top: 10px;
}

.balance_item_content p span {
  display: inline-block;
  font-size: 50px;
  line-height: 1;
  margin-left: 5px;
}

.balance_item_one {
  background-size: cover;
  // background-image: url('../images/bg-one.jpg');
  background-repeat: no-repeat;
}

.balance_item_two {
  background-color: var(--secondary-color);
  text-align: center;
}

.balance_item_three {
  background-color: var(--secondary-color);
}

.balance_item_four {
  background-color: var(--secondary-color);
}

.btn_logout {
  display: table;
  text-align: center;
  color: #CC3B3B;
  font-size: 22px;
  margin: auto;
}

.btn_logout > img {
  display: inline-block;
  margin-left: 10px;
}

.btn_logout:hover {
  color: #CC3B3B;
}

.box_link_item {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 30px 20px;
  color: #FFFFFF;
  font-size: 22px;
  background-color: var(--secondary-color);
  height: 120px;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.box_link_item span {
  display: inline-block;
  margin-inline-start: 10px;
}

.box_link_item img {
  display: inline-block;
  max-width: 40px;
}

.bg_color1 {
  background-color: var(--secondary-color);
}

.bg_color2 {
  background-color: var(--secondary-color);
}

.bg_color3 {
  background-color: var(--secondary-color);
}

.bg_color4 {
  background-color: var(--secondary-color);
}

.box_link_item:hover {
  color: #FFFFFF;
  background-color: var(--primary-color);
}

.balance_item_two.box_link_item span {
  display: block;
  margin-top: 10px;
  font-size: 28px;
  margin-right: 0;
  margin: auto;
}

.link_lg img {
  width: 70px;
  max-width: 70px;
}

.box_user_profile {
  position: relative;
  margin-bottom: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  background-color: var(--secondary-color);
}

.user_thumb {
  position: relative;
}

.user_thumb span {
  display: block;
  width: 160px;
  height: auto;
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
}

.user_thumb span > img {
  width: 90px;
  max-width: 90px;
  height: 90px;
}

.btn_edit_profile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45px;
  height: 45px;
  background-color: #FFFFFF;
  border-radius: 12px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 5px;
}

.btn_edit_profile img {
  margin: auto;
  max-height: 100%;
  width: 20px;
}

.btn_edit_profile:hover {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
}

.user_txt {
  margin-inline-start: 20px;
}

.user_txt h2 {
  color: #FFFFFF;
  font-size: 24px;
}

.user_txt p {
  color: #FFFFFF;
  font-size: 20px;
}


.noti_switch .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.side-bar {
  background-color: var(--primary-color);
}

.Toastify__toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  .Toastify__toast-icon {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

.order-desc {
  ul {
    list-style: auto;
    @apply text-lg;
  }
}
// .react-datepicker-wrapper {
//   width: 100%;
// }

.swiper-wrapper {
  justify-content: center;
}

a.disabled,
div.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}