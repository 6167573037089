/* Global settings called from index.tsx. */

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #176775;
    --secondary-color: #AC812D;
    --other-color: #38929B;
}
